<!-- @format -->
<template>
  <b-card title="添加会员">
    <b-form>
      <b-row>
        <b-col md="4">
          <b-form-group label-cols="2" label-align="center" label="姓">
            <b-form-input v-model="editData.lastname" placeholder="请输入姓" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-group label-cols="2" label-align="center" label="名">
            <b-form-input v-model="editData.firstname" placeholder="请输入名" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-group label-cols="2" label-align="center" label="邮箱">
            <b-form-input v-model="editData.email" placeholder="请输入邮箱" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-group label-cols="2" label-align="center" label="手机号码">
            <b-form-input v-model="editData.mobile" placeholder="请输入手机号码" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-group label-cols="2" label-align="center" label="国家">
            <b-form-input v-model="editData.country" placeholder="请输入国家" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-group label-cols="2" label-align="center" label="生日">
            <flat-pickr
              v-model="editData.birth"
              class="form-control invoice-edit-input"
              placeholder="选择日期"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-group label-cols="2" label-align="center" label="住址">
            <b-form-input v-model="editData.address" placeholder="请输入住址" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-group label-cols="2" label-align="center" label="住址2">
            <b-form-input v-model="editData.address2" placeholder="请输入住址" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-group label-cols="2" label-align="center" label="城市">
            <b-form-input v-model="editData.city" placeholder="请输入城市" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-group label-cols="2" label-align="center" label="州">
            <b-form-input v-model="editData.state" placeholder="请输入州" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-group label-cols="2" label-align="center" label="邮政编码">
            <b-form-input v-model="editData.post_code" placeholder="请输入邮政编码" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-group label-cols="2" label-align="center" label="性别">
            <v-select
              v-model="editData.gender"
              placeholder="请选择性别"
              :options="options"
              label="name"
              :reduce="(item)=>item.gid"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-group label-cols="2" label-align="center" label="密码">
            <b-form-input v-model="editData.password_hash" placeholder="请输入密码" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-group label-cols="2" label-align="center" label="推荐人">
            <!-- <v-select
                  v-model="editData.pid"
                  placeholder="请选择推荐人"
                  :options="vipOptions"
                  label="id"
                  :reduce="(item)=>item.id"
                /> -->
            <b-form-input v-model="editData.pid" placeholder="请输入推荐人ID" @change="tuijian(editData.pid)" />
            <b-form-input v-model="tj_name" disabled style="width:60px" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-group label-cols="2" label-align="center" label="等级">
            <v-select
              v-model="editData.level_id"
              placeholder="请选择等级"
              :options="levelOptions"
              label="name"
              :reduce="(item)=>item.id"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-button variant="primary" type="submit" @click.prevent="validationForm" :disabled="isRequest"> 提交 </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { reactive, ref, onUnmounted, getCurrentInstance } from '@vue/composition-api'
import { required, url } from '@core/utils/validations/validations'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'
import VipManagerModule from '@/views/GeneralManagement/VipManager/List/VipManagerModule'
import axios from '@axios'
import { useToast } from 'vue-toastification/composition'

export default {
  name: 'BrandCreate',
  components: {
    BCard,
    flatPickr,
    vSelect,
  },
  data() {
    return {
      tj_name: '无',
      options: [
        {
          gid: 1,
          name: '男',
        },
        {
          gid: 2,
          name: '女',
        }
      ],
      levelOptions: [],
      vipOptions: []
    }
  },
  created() {
    axios.get('/user/userLevelList').then(response => {
      this.levelOptions = response.data// 绑定数据！
    })
    axios.get('/user/list').then(response => {
      console.log(response.data)
      this.vipOptions = response.data.list// 绑定数据！
    })
  },
  methods: {
    tuijian(e) {
      console.log(e)
      axios.get(`/user/getUserInfoById?id=${e}`).then(response => {
        console.log(response.data)
        this.tj_name = response.data.real_name
      // this.vipOptions = response.data.list// 绑定数据！
      })
    }
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'vip-module'
    const toast = useToast()
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, VipManagerModule)
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })
    const isRequest = ref(false)
    const editData = reactive({
      real_name: '',
      email: '',
      mobile: '',
      country: '',
      birth: '',
      address: '',
      address2: '',
      post_code: '',
      state: '',
      city: '',
      gender: '',
      password_hash: '',
      pid: '',
      level_id: '',
    })
    const { proxy } = getCurrentInstance()
    const createProdBrand = ref(null)
    const validationForm = () => {
      isRequest.value = true

      store.dispatch('vip-module/createInvoices', { ...editData })
        .then(response => {
          if (response.code == 0) {
            toast.success(response.msg || '添加成功')
            setTimeout(() => {
              isRequest.value = false
              proxy.$router.back()
            }, 1000)
          } else {
            toast.error(response.msg)
            isRequest.value = false
          }
        })

      // createProdBrand.value
      //   .validate()
      //   .then(response1 => {
      //     console.log(editData)
      //     if (response1) {

      //     } else {
      //       isRequest.value = false
      //     }
      //   })
      //   .catch(error => {
      //     isRequest.value = false
      //     console.log(error)
      //   })
    }

    return {
      validationForm,
      editData,
      createProdBrand,
      isRequest,
      required,
      url,
    }
  },
}
</script>

<style scoped></style>
